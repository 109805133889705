import React from 'react';

import './styles.scss';

interface VideoPopupProps {
  params: {
    url: string,
    srt?: {
      transcription?: string,
      translation?: string
    }
  },
  open: boolean,
  country: string,
  language: string
};

const VideoPopup:React.FC<VideoPopupProps> = ({params, open, country, language}) => {
  
  const baseURL = "https://the-home.s3.eu-west-2.amazonaws.com/";
  const videoURL = baseURL + "videos/"+country+"/";
  const subsURL = baseURL + "subs/"+country+"/";

  // set the translation to default if we're watching in a different language to the native language
  const translationIsDefault = country != language ? true : false;

  return (
      open && <video controls autoPlay="autoplay" crossOrigin="anonymous" playsInline="playsInline">
        <source src={videoURL + params.url} type="video/mp4" />
        {subsURL+params.srt.transcription && <track label={country == "jp" ? "Japanese" : "English"}  srcLang={country == "jp" ? "ja" : "en"} src={subsURL+"transcription/"+params.srt.transcription}  />}
        {subsURL+params.srt.translation && <track label={country == "en" ? "Japanese" : "English"}  srcLang={country == "en" ? "ja" : "en"} kind="subtitles" src={subsURL+"translation/"+params.srt.translation} default={translationIsDefault} />}
      </video>
    );
}

export default VideoPopup;
