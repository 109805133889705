import React, {useState, useCallback} from 'react';
import './styles.scss';

interface QuestionnaireProps {
  country: string,
  hidePopUp
};

const Questionnaire:React.FC<QuestionnaireProps> = props => {
  const {country, hidePopUp} = props;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [location, setLocation] = useState('');
  const [otherPrefecture, setOtherPrefecture] = useState('');
  const [otherCountry, setOtherCountry] = useState('');
  const [age, setAge] = useState('');
  const [checked, setChecked] = useState(false); 
  const [submitted, setSubmitted] = useState(false);

  const handleNameUpdate = useCallback((e) => {
    setName(e.target.value);
  }, [setName]);

  const handleCheckboxUpdate = useCallback((e) => {
    setChecked(e.target.value);
  }, [setChecked]);

  const handleEmailUpdate = useCallback((e) => {
    setEmail(e.target.value);
  }, [setEmail]);

  const handleOtherPrefectureUpdate = useCallback((e) => {
    setOtherPrefecture(e.target.value);
  }, [setOtherPrefecture]);

  const handleOtherCountryUpdate = useCallback((e) => {
    setOtherCountry(e.target.value);
  }, [setOtherCountry]);

  const handleLocationUpdate = useCallback((e) => {
    setLocation(e.target.value);
  }, [setLocation]);

  const handleAgeUpdate = useCallback((e) => {
    setAge(e.target.value);
  }, [setAge]);

  const handleSubmit = useCallback((e) => {
    if(name.length === 0) {
      alert('Please enter your name');
      return;
    }
    /*if(!/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(email)) {
      alert('Please enter your email address');
      return;
    }*/

    submitForm();

    setSubmitted(true);
  }, [setSubmitted, name, email]);

  /** HUBSPOT SUBMISSION */
  const submitForm = () => {


    // submit to Hubspot

    const result = fetch(
    `https://api.hsforms.com/submissions/v3/integration/submit/25105422/ef9bff44-5b46-47d3-9cdc-3bafa29ae840`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': "application/json"
      },
      body: JSON.stringify({
          fields: [
            {
                "name": "firstname",
                "value": name
            },
            {
                "name": "where_do_you_live_",
                "value": [location, otherPrefecture, otherCountry].join(" ")
            },
            {
                "name": "email",
                "value": email
            },              
            {
                "name": "what_age_are_you_",
                "value": age
            }                                  
          ],              
          context: {
            pageUri: window.location ? window.location.hostname + window.location.pathname : '',
            pageName: document ? document.title : ''
          }
      }),
    },
  ).then(response => {
    if(response.ok) {
        setSubmitted(true);
        window.scroll({ top: 0, behavior: 'smooth' });                            
    } else {
        setSubmitted(false);        
    }
  });
  
  return false;

};  


  const handleClose = useCallback((e) => {
    hidePopUp();
  }, []);

  return (<div className="questionnaire-wrapper">
    <div className="questionnaire-inner">
      {country === "en" && (
        <>
          <img className="questionnaire-logo" src="/logo-en.svg" />
          {!submitted && (
            <>
              <p>Welcome to The Home.</p>
              <p>Let’s get you signed in.</p>
              <p>Please note: GDPR regulations compel us to inform you that we will only use this information to contact you to discuss your potential care home needs now, in the future, and in any possible futures hitherto not yet known about.</p>
              <p className="questionnaire-question-wrapper">What would you like us to call you?  <input onChange={handleNameUpdate} value={name} type="text" /></p>
              <p className="questionnaire-question-wrapper">What is your email address? <input onChange={handleEmailUpdate} value={email} type="email" /></p>
              <p className="questionnaire-question-wrapper">I agree that you may contact me about The Home by email<input onChange={handleCheckboxUpdate} checked={checked} type="checkbox" /></p>
              <button onClick={handleSubmit}>Continue</button>
            </>
          )}
          {submitted && (
            <>
              <p>{`Thank you ${name}.`}</p>
              <p>Press 'Continue' to explore The Home</p>
              <button onClick={handleClose}>Continue</button>
            </>
          )}
          
        </>
      )}
      {country === "jp" && (
        <>
          <img className="questionnaire-logo" src="/logo-jp.svg" />
          {!submitted && (
            <>
              <p>ようこそ！ 有料老人ホーム〈あおぞら〉は、英国に本社をおく〈The Home〉の日本支部です。</p>
              <p>施設を訪問いただくには、こちらで受付をお願いします！ ※取得した個人情報は、将来むかえる老いや介護について、あなたに演劇的なソリューションをご案内する場合にのみ使用されます。法令に基づく場合を除き、個人情報を第三者に提供することはありません。</p>
              <p>※取得した個人情報は、将来むかえる老いや介護について、あなたに演劇的なソリューションをご案内する場合にのみ使用されます。法令に基づく場合を除き、個人情報を第三者に提供することはありません。</p>
              <p className="questionnaire-question-wrapper">ここでのニックネームを決めましょう！ <input onChange={handleNameUpdate} value={name} type="text" /></p>
              <p className="questionnaire-question-wrapper">メールアドレスを入力してください。 <input onChange={handleEmailUpdate} value={email} type="email" /></p>
              <p className="questionnaire-question-wrapper">お住まいの地域はどちらですか。
                <select onChange={handleLocationUpdate} >
                  <option value="埼玉県">埼玉県</option>
                  <option value="東京都">東京都</option>
                  <option value="その他の地域">その他の地域</option>
                  <option value="海外">海外</option>            
                </select></p>                                   
              <p className="questionnaire-question-wrapper">あなたの年齢をお教えください。 <select onChange={handleAgeUpdate}>
                  <option value="10歳未満">10歳未満</option>
                  <option value="10代">10代</option>
                  <option value="20代">20代</option>
                  <option value="30代">30代</option>
                  <option value="40代">40代</option>
                  <option value="50代">50代</option>
                  <option value="60代">60代</option>
                  <option value="70代">70代</option>
                  <option value="80代">80代</option>
                  <option value="90代">90代</option>
                  <option value="100歳以上">100歳以上</option>
              </select>

              </p>

              <button onClick={handleSubmit}>見学をはじめましょう！</button>
            </>
        )}
        {submitted && (
          <>
            <p>さあ、あなたのバーチャルな分身を作って、見学をはじめましょう！</p>
          </>
        )}            
        </>
      )}
    </div>
  </div>);
};

export default Questionnaire;