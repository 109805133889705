import React, {useEffect, useState} from 'react';
import { Link } from "gatsby"
import "./styles.scss"

import menu_icon_look from '../../images/menu-icon-look.png';
import menu_icon_talk from '../../images/menu-icon-talk.png';
import menu_icon_enter from '../../images/menu-icon-enter.png';
import menu_icon_exit from '../../images/menu-icon-exit.png';
interface SidebarProps {
  showPopup: (string, object) => void,
  name: {
    en: string,
    jp: string
  },
  menus: [
    {
      id: string,
      icon: string,
      overlays: [number],
      text: object,
      subtitle: object,
      action: string,
      params: {
        url?: string,
        type?: string,
        text?: string,
        country?: string,
        target?: string,
        sourceFragment?: string,
        destFragment?: string,
      }
    }
  ],
  language: string,
  country: string
};

const Sidebar:React.FC<SidebarProps> = ({
  name,
  menus,
  showPopup,
  country,
  language
}) => {

  const [fragment, setFragment] = useState(null);

  useEffect(() => {
    setFragment((window.location.hash.length > 0 && window.location.hash) || null);
  }, [setFragment]);


  const handlePopupItemClick = (e:MouseEvent<HTMLAnchorElement, MouseEvent>, type:String, params:Object) => {
    e.preventDefault();
    showPopup(type, params);
  };

  const menuIcons = {
    look: menu_icon_look,
    talk: menu_icon_talk,
    enter: menu_icon_enter,
    exit: menu_icon_exit,
  };  

  console.log(fragment);

  return (
    <div className="sidebar-wrapper">
      <h1 className="sidebar-header">{name[language]}</h1>
      <div className="sidebar-intro"></div>
      <ul className="sidebar-menu-wrapper">
        {menus && menus.map((menu_item, index) => {
          console.log(menu_item.params.sourceFragment, menu_item.params.sourceFragment,fragment, menu_item.action, menu_item.params.target, `#${menu_item.params.sourceFragment}`===fragment,menu_item.action === 'link', menu_item.params.target !== "_blank", menu_item.params.sourceFragment && `#${menu_item.params.sourceFragment}`===fragment && menu_item.action === 'link' && menu_item.params.target !== "_blank");
          if(menu_item.params.sourceFragment && `#${menu_item.params.sourceFragment}` !== fragment) return null;

          const destination = `${menu_item.params.type === "external" ?  menu_item.params.url : `/${menu_item.params.country ? menu_item.params.country : country}/${language}/${menu_item.params.url}`}${menu_item.params.destFragment ? `#${menu_item.params.destFragment}` : ''}`;
          
          return (
            <li key={index} className={`sidebar-menu-item sidebar-menu-type-${menu_item.action.replace(/_/g, '-')}`} >
              <span className="sidebar-menu-item-icon-wrapper">
                {menu_item.icon && menuIcons.hasOwnProperty(menu_item.icon) &&  <img src={menuIcons[menu_item.icon]} />}
              </span>
              {menu_item.action.includes('_pop_up') &&  (<a href="#" onClick={(e) => handlePopupItemClick(e, menu_item.action, menu_item.params)}>{`${menu_item.text[language]}`} <span className='subtitle'>{menu_item.subtitle ? `${menu_item.subtitle[language]}` : ""}</span></a>)}

              {!menu_item.params.sourceFragment && menu_item.action === 'link' && menu_item.params.target !== "_blank" && <Link target={menu_item.params.target ? menu_item.params.target : ""} to={destination}>{menu_item.text[language]}</Link>}
              {!menu_item.params.sourceFragment && menu_item.action === 'link' && menu_item.params.target === "_blank" && <a target={menu_item.params.target}  href={destination} >{menu_item.text[language]}</a>}
              {menu_item.params.sourceFragment && `#${menu_item.params.sourceFragment}`===fragment && menu_item.action === 'link' && menu_item.params.target !== "_blank" && <Link target={menu_item.params.target ? menu_item.params.target : ""} to={destination}>{menu_item.text[language]}</Link>}
              
            </li>
          )
        })}
      </ul>
    </div>
  )
};

export default Sidebar;
