import React, {useState, useCallback, useEffect} from 'react';
import Popup from '../components/Popups/Popup';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Stage from '../components/Stage';

import './styles.scss';

interface RoomProps {
  pageContext: {
    slug: string,
    name: string,
    room_image: string,
    overlays: [{id: number, url: string}],
    menus: [
      {
        id: string,
        icon: string,
        overlays: [number],
        text: string,
        action: string,
        params: {
          url: string
        }
      }
    ],
    language: string,
    country: string
  },
  location: string


};

const popupTypes = [
  'video_pop_up', 
  'minigame_pop_up', 
  'text_pop_up', 
  'image_pop_up', 
]

const Room: React.FC<RoomProps> = ({
  pageContext: {
    name, 
    room_image,
    overlays,
    menus,
    language,
    country,
    slug
  }
}) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupType, setPopupType] = useState(null);
  const [popupParams, setPopupParams] = useState({});

  const showPopup = useCallback((type, params) => {
    if(popupTypes.includes(type)) {
      setPopupType(type);
      setPopupParams(params);
      setPopupVisible(true);
    }
  }, [setPopupVisible, setPopupType, setPopupParams]);

  const hidePopup = useCallback(() => {
    setPopupVisible(false);
  }, [setPopupVisible]);

  useEffect(() => {
    window.addEventListener("keydown", (e:KeyboardEvent) => {
      if (e.key === 'Escape')
        hidePopup();
    });

    // Register an event to close the popup if user clicks outside.  We travel up the DOM tree to determine if the target is within the pop up inner
    window.addEventListener("mousedown", (e:MouseEvent) => {
      e.stopPropagation();
      let currentTarget = e.target;
      let insidePopup = false;
      while (currentTarget !== null) {
        if(currentTarget.classList.contains('popup-inner')) {
          insidePopup = true;
          currentTarget = null;
        } else {
          currentTarget = currentTarget.parentElement;
        }
      }
      if (!insidePopup)
        hidePopup();
    });
  })

  return (
    <>
      <Header country={country} language={language} urlParts={[country, language, slug]} />
      <div className={`room-wrapper lang-${language} country-${country}`}>
        <Sidebar language={language} country={country} name={name} menus={menus} showPopup={showPopup} />
        <Stage language={language} country={country} room_image={room_image} overlays={overlays} />
        <Popup language={language} country={country} visible={popupVisible} type={popupType} params={popupParams} hidePopup={hidePopup} />
      </div>
    </>
  );
};

export default Room;